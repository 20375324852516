// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Button, FormControlLabel, Modal, Paper, TextField, Typography, IconButton, Box,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { BackdropComponent } from "./BackdropComponent";
import type { DefaultTheme } from "../../themes";
import { type UseInfoBannerModalHook } from "../../hooks/useInfoBannerModal";

const StyledPaper: Paper = withStyles((theme: DefaultTheme): * => ({
  root: {
    position: "absolute",
    top: "calc(50% )",
    left: "calc(50%)",
    transform: "translate(-50%, -50%)",
    width: `calc(100% - ${theme.spacing(4)})`,
    maxWidth: 320,
    minWidth: 320,
    maxHeight: `calc(100% - ${theme.spacing(4)})`,
    boxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.2)",
    padding: theme.spacing(2),
    borderRadius: theme.shape.cardBorderRadius,
    "&:focus-visible": {
      outline: "none",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      maxWidth: 460,
    },
  },
}))(Paper);

const StyledFormControlLabel: FormControlLabel = withStyles((theme: DefaultTheme): * => ({
  root: {
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "flex-start",
    gap: "5px",
    margin: "0 0 5px",
    "& .MuiFormControlLabel-label": {
      fontWeight: 500,
      "&.Mui-disabled": {
        color: theme.palette.text.main,
      },
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInputBase-root": {
      "&.Mui-disabled": {
        background: "transparent",
      },
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiTextField-root": {
        width: "290px",
      },
    },
  },
}))(FormControlLabel);

const StyledTextField: TextField = withStyles({
  root: {},
})(TextField);

const InfoBannerModal: StatelessFunctionalComponent<UseInfoBannerModalHook> = ({
  isLoading,
  open,
  email,
  affiliateId,
  onChangeEmail,
  onSendData,
  onCloseModal,
}) => (
  <Modal
    open={open}
    BackdropComponent={BackdropComponent}
    data-testid="info-banner-modal"
  >
    <StyledPaper>
      <IconButton
        data-testid="close"
        onClick={onCloseModal}
        size="small"
        disableRipple
        sx={{
          position: "absolute", right: "10px", top: "10px", color: "transparent.text",
        }}
      >
        <Close />
      </IconButton>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Typography data-testid="modal-title" variant="gradientH4" component="p" mb={2}>
          Your info
        </Typography>

        <StyledFormControlLabel
          data-testid="affiliateId-field"
          disabled
          control={(
            <StyledTextField />
          )}
          label="Affiliate ID"
          name="affiliateId"
          value={affiliateId}
        />

        <StyledFormControlLabel
          data-testid="email-field"
          disabled={isLoading}
          onChange={({ target }) => {
            onChangeEmail(target.value);
          }}
          control={(
            <StyledTextField />
          )}
          label="Email"
          name="email"
          value={email}
        />

        <Button
          fullWidth
          disabled={!email || isLoading}
          variant="gradient"
          data-testid="next"
          sx={{
            maxWidth: 290, marginLeft: "auto", marginTop: { xs: "15px", md: "25px" }, display: "flex",
          }}
          onClick={() => onSendData({ isBannerAccepted: true })}
        >
          Confirm my request
        </Button>
      </Box>
    </StyledPaper>
  </Modal>
);

export default InfoBannerModal;
