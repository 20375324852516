/* eslint-disable import/max-dependencies */
// @flow
import type { Node, StatelessFunctionalComponent } from "react";
import React from "react";
import {
  Box, Skeleton, Tab, Tabs as MuiTabs,
} from "@mui/material";
import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import { withStyles } from "@mui/styles";
import type { Option } from "@fas/cpa-state-manager/redux/reducers";
import { getPaymentByPaymentMethodId } from "@fas/cpa-cabinet-ui";
import type { PaymentData } from "@fas/cpa-cabinet-ui";
import { type ConfirmOptions, useConfirm } from "material-ui-confirm";
import { PAYMENT_METHOD_FORM } from "../../constants";

export type Props = {
  options: Option[],
  isLoading: boolean,
  disabled?: boolean,
  isChanged?: boolean,
  reset: () => mixed,
};

const Tabs: StatelessFunctionalComponent<{}> = withStyles((theme: *): * => ({
  root: {
    maxWidth: "fit-content",
    background: theme.palette.transparent.light,
    borderRadius: theme.shape.borderRadius,
    "&>*>*>.MuiTab-root.Mui-selected": {
      background: theme.palette.transparent.light,
      borderRadius: theme.shape.borderRadius,
    },
    "&>*>*>.MuiTab-root": {
      ...theme.typography.subtextBold,
      height: "72px",
      padding: "5px 15px",
      margin: 0,
    },
    "& svg": {
      margin: "auto",
    },
  },
  indicator: {
    background: "transparent",
  },
}))(MuiTabs);

function SelectPaymentMethod({
  disabled = false,
  isChanged = false,
  options = [],
  isLoading, reset,
}: Props): Node {
  const {
    value: paymentMethodId,
    onChange: handlePaymentMethodChange,
  }: { value: mixed, onChange: (mixed) => mixed } = useFormField(PAYMENT_METHOD_FORM, "paymentMethodId");

  const confirm: (options?: ConfirmOptions) => Promise<void> = useConfirm();
  const handleTabChange: (
    event: MouseEvent<HTMLButtonElement>,
    newTab: number
  ) => void = (event, newPaymentMethodId) => {
    if (isChanged) {
      confirm({
        description: "All changed data will be not applied.",
      })
        .then(() => {
          reset();
          handlePaymentMethodChange(newPaymentMethodId);
        })
        .catch((): null => null);
    }
    else {
      reset();
      handlePaymentMethodChange(newPaymentMethodId);
    }
  };
  const payments: Array<PaymentData> = options
    .reduce((acc: Array<PaymentData>, { value }: Option): Array<PaymentData> => {
      const payment: PaymentData | void = getPaymentByPaymentMethodId(value);
      if (payment) {
        acc.push(payment);
      }
      return acc;
    }, []);

  const isSupportPaymentMethodId: boolean = !!getPaymentByPaymentMethodId(String(paymentMethodId));

  return (
    <Box>
      {options.length === 0 && isLoading && (
        <Skeleton variant="rectangular" height={72} />
      )}
      <Tabs
        className="contained"
        variant="scrollable"
        value={isSupportPaymentMethodId && paymentMethodId}
        onChange={handleTabChange}
      >
        { payments.map(({
          id, label, icon, iconActive,
        }: PaymentData): Node => (
          <Tab
            data-testid={label}
            disabled={isLoading || disabled}
            key={id}
            value={id}
            label={label}
            icon={String(paymentMethodId) === String(id) ? iconActive : icon}
          />
        )) }
      </Tabs>
    </Box>
  );
}

export default SelectPaymentMethod;
