// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box,
  IconButton,
  Divider,
  Typography,
  Grid,
  Tooltip,
  Link,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { HelpOutline, FavoriteOutlined } from "@mui/icons-material";
import environment from "environment";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import { withState } from "@fas/cpa-cabinet-ui";
import { LogoLight, LogoMob } from "../../icons";
import Logout from "../../icons/Logout";
import { cabinetAppBarStyles, verticalDividerStyles } from "../../styles";
import { APP_BAR_HEIGHT } from "../../pages/Cabinet/types/Cabinet.types";
import IconLinkButton from "./IconLinkButton";
import { useInfoBanner, type UseInfoBannerHook } from "../../hooks/useInfoBanner";
import InfoBannerComponent from "../InfoBanner";

const StyledAppBar: StatelessFunctionalComponent<*> = withStyles(cabinetAppBarStyles)(Grid);
const StyledDivider: StatelessFunctionalComponent<*> = withStyles(verticalDividerStyles)(Divider);

const InfoBanner: StatelessFunctionalComponent<$Diff<UseInfoBannerHook, UseInfoBannerHook>> = withState(
  InfoBannerComponent,
  useInfoBanner
);

const CabinetAppBar: StatelessFunctionalComponent<{}> = () => (
  <StyledAppBar container alignItems="center" sx={{ height: APP_BAR_HEIGHT }}>
    <Box component={Link} href={environment.links.blog}>
      <Grid item sx={{ py: "20px", display: { xs: "none", sm: "flex" } }}>
        <LogoLight />
      </Grid>
      <Grid item sx={{ py: "20px", display: { xs: "flex", sm: "none" } }}>
        <LogoMob />
      </Grid>
    </Box>
    <StyledDivider orientation="vertical" flexItem />
    <Grid item>
      <Typography variant="gradientH5">SMARTLINK</Typography>
    </Grid>
    <Box sx={{ flexGrow: 1 }} />

    <Box sx={{ display: { xs: "none", md: "block" } }}>
      <InfoBanner />
    </Box>

    <Tooltip title={(
      <div style={{ textAlign: "center" }}>
        Ukraine needs your support!
        <br />
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Wire your money via the National Bank of Ukraine. Don't worry - it's safe.
      </div>
    )}
    >
      <IconButton
        variant="contained"
        component="a"
        href="https://bit.ly/350UJDS"
        target="_blank"
      >
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="flag_ukr" gradientTransform="rotate(90)">
              <stop offset="46%" stopColor="#4588EF" />
              <stop offset="46%" stopColor="#FFD33A" />
            </linearGradient>
          </defs>
        </svg>
        <FavoriteOutlined style={{ fill: "url(#flag_ukr)" }} />
      </IconButton>
    </Tooltip>

    <Grid item>
      <IconLinkButton to={environment.links.faq} data-testid="faq-btn">
        <HelpOutline />
      </IconLinkButton>
      <IconLinkButton to={environment.links.userInfo} data-testid="profile-btn" lookup="/profile" sx={{ display: { xs: "inline-flex", sm: "none" } }}>
        <AccountCircleOutlined />
      </IconLinkButton>
    </Grid>
    <Grid item>
      <IconButton
        sx={{ display: { xs: "none", sm: "inline-flex" } }}
        size="large"
        color="inherit"
        component="a"
        href={environment.links.logout}
      >
        <Logout />
      </IconButton>
    </Grid>
  </StyledAppBar>
);

export default CabinetAppBar;
