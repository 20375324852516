// @flow
import environment from "environment";
import { getRequestService } from "@fas/cpa-state-manager/services/request";
import { type Response } from "@fas/ui-framework/lib/services/request";
import type { Environment } from "@fas/cpa-state-manager/services/auth";
import type { SelectedValuesType } from "@fas/cpa-state-manager/redux/reducers/linkGenerator/reducer";
import {
  MAP_API_TIMEOUT, NO_PAYMENT_DETAILS_MODAL, optionsList, NATURE_OF_TRAFFIC_MODAL, INFO_BANNER,
} from "../constants";
import type { SmartOfferType } from "../components/SmartlinkList/types/SmartlinkList.types";

export type User = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmation?: string,
  imType: string,
  imName: string,
  howFoundOut: string,
  photo: string,
};

export type UserContactInfo = {
  companyName: string,
  companyUrl: string,
  address: string,
  country: string,
  city: string,
  state: string,
  zip: string,
  taxSSN: string,
  phoneNumber: string,
};

export type Manager = {
  name: string,
  email: string,
  skype: string,
  telegram: string,
  photo: string,
};

export type PaymentInfoType = {
  bankAccountName: string,
  bankAddress: string,
  beAddress: string,
  beName: string,
  swiftCode: string,
  beCountry: string,
  iban: string,
  payoutCountry: string,
  accountNumber: string,
  bankName: string,
  paymentNote: string,
  paxumMail: string,
  webmoneyWallet: string,
  bitcoin: string,
  qiwi: string,
  yandex: string,
  capitalistWallet: string,
  payoneerWallet: string,
  payPalWallet: string,
  genome: string,
  paysera: string,
  usdt: string,
  vatNumber: string,
  paymentMethodId: number,
  isEdit: boolean,
  currency: string,
};

export type DashboardItemType = {
  date: string,
  smartClicks: number,
  leads: number,
};

export type Offer = {
  country: string,
  webMetric: string,
  mobMetric: string,
};

const requestService: $Call<typeof getRequestService> = getRequestService();

export const getUserInfo: () => Promise<User> = () => requestService
  .get(environment.endpoints.get.userInfo)
  .then(({ data }: Response<User>): Manager => data);

export const getManagerInfo: () => Promise<Manager> = () => requestService
  .get(environment.endpoints.get.managerInfo)
  .then(({ data }: Response<Manager>): Manager => data);

export const postUserInfo: (FormData) => Promise<*> = (user) => requestService
  .post(
    environment.endpoints.post.userInfo,
    user,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

export const getUserContactInfo: () => Promise<UserContactInfo> = () => requestService
  .get(environment.endpoints.get.contactInfo)
  .then(({ data: response }: Response<UserContactInfo>): UserContactInfo => response.data);

export const postUserContactInfo: ($Shape<UserContactInfo>) => Promise<*> = (contactInfo) => requestService
  .post(environment.endpoints.post.contactInfo, contactInfo);

export const getPaymentInfo: () => Promise<PaymentInfoType> = () => requestService
  .get(environment.endpoints.get.getPaymentInfo)
  .then(({ data: response }: Response<PaymentInfoType>): PaymentInfoType => response.data);

export const postPaymentInfo: ($Diff<PaymentInfoType, {
  isEdit: boolean, currency: string
}>) => Promise<*> = (data) => requestService
  .post(environment.endpoints.post.paymentInfo, data);

export const getOutstandingBalance: () => Promise<string> = () => requestService
  .get(environment.endpoints.get.outstandingBalance, { timeout: MAP_API_TIMEOUT })
  .then(({ data: response }: Response<string>): string => response.data.outBalance);

export const getDashboardData: ({|
  filters: { date: [{ from: string, to: string }] },
  group: [string],
|}) => Promise<DashboardItemType[]> = (data) => requestService
  .post(environment.endpoints.post.getDashboardData, data, { timeout: MAP_API_TIMEOUT })
  .then(({ data: response }: Response<DashboardItemType[]>): DashboardItemType[] => response.data);

export const getBestOffers: ({ environment: Environment }) => Promise<{ title: string, data: Offer[] }> = ({
  environment,// eslint-disable-line
}) => requestService
  .get(environment.endpoints.get.topOffers)
  .then(({ data: response }: Response<{ title: string, data: Offer[] }>): { title: string, data: Offer[] } => response);

export const smartOfferCheck: (
  SelectedValuesType
) => Promise<boolean> = (params) => requestService.get(environment.endpoints.get.smartOfferCheck, {
  params,
}).then(({ data: { status } }: { data: { status: boolean }}): boolean => status);

function prepareSmartOfferParams(data: SelectedValuesType): {[string]: number} {
  return Object.keys(data).reduce((acc: {[string]: number}, key: string): {[string]: number} => ({
    ...acc,
    [key]: Number(data[key]),
  }), {});
}

type SmartOfferIncoming = {
  link: string,
  name: string,
  isTraffic?: number,
  isStraight?: number,
  isMainstream?: number,
  isPreLander?: number,
  isPPL?: number,
}

export const generateSmartOffer: (
  SelectedValuesType
) => Promise<SmartOfferType> = (
  params
) => requestService.put(environment.endpoints.put.generateSmartOffer, prepareSmartOfferParams(params))
  .then(({ data: { data } }: { data: { data: SmartOfferIncoming } }): SmartOfferType => prepareSmartOfferOptions(data));

function prepareSmartOfferOptions(offer: SmartOfferIncoming): SmartOfferType {
  return {
    link: offer.link,
    name: offer.name,
    options: ["isTraffic", "isStraight", "isMainstream", "isPreLander", "isPPL"]
      .filter((k: string): boolean => offer[k] !== undefined)
      .map((k: string): string => optionsList[k][offer[k]] && optionsList[k][offer[k]].title),
  };
}

export const getAffiliateSmartOfferList: () => Promise<SmartOfferType[]> = () => requestService
  .get(environment.endpoints.get.getSmartOfferList)
  .then(
    ({ data: { data } }: { data: { data: SmartOfferIncoming[] }
      }): SmartOfferType[] => data.map(prepareSmartOfferOptions)
  );

export const getManagerCustomLinkList: () => Promise<SmartOfferType[]> = () => requestService
  .get(environment.endpoints.get.getCustomLinkList)
  .then(
    ({ data: { data } }: { data: { data: SmartOfferIncoming[] }
      }): SmartOfferType[] => data.map(prepareSmartOfferOptions)
  );

type ModalFlags = {
  showSourceComplianceModal: boolean,
  showPaymentInfoModal: boolean,
  showInfoBanner: boolean,
}

export const getModalFlags: () => Promise<*> = () => requestService
  .get(environment.endpoints.get.getModals)
  .then(
    ({ data }: { data: ModalFlags }): * => ({
      [NATURE_OF_TRAFFIC_MODAL]: data.showSourceComplianceModal,
      [NO_PAYMENT_DETAILS_MODAL]: data.showPaymentInfoModal,
      [INFO_BANNER]: data.showInfoBanner,
    })
  );

export const getNatureOfTraffic: () => Promise<*> = () => requestService
  .get(environment.endpoints.get.getNatureOfTraffic)
  .then(
    ({ data: { natureOfTraffic } }: { data: { natureOfTraffic: string } }): string => natureOfTraffic
  );

export const updateNatureOfTraffic: (string, boolean) => Promise<*> = (
  natureOfTraffic,
  isSourceConfirmed
) => requestService.post(environment.endpoints.post.updateNatureOfTraffic, { natureOfTraffic, isSourceConfirmed });

export const updateInfoBanner: (string, boolean) => Promise<*> = (
  email,
  isBannerAccepted
) => requestService.post(environment.endpoints.post.updateInfoBanner, { email, isBannerAccepted });
