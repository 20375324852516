// @flow
import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import { INFO_BANNER } from "../../constants";
import { useInfoBannerModal } from "../useInfoBannerModal";

export type UseInfoBannerHook = {
  show: boolean,
  onShowModal: () => void,
  onCloseBanner: () => void,
};

function useValue<T>(key: string): { value: T, onChange: (T) => * } {
  const {
    value,
    onChange,
  }: { value: T, onChange: (T) => * } = useFormField(INFO_BANNER, key);

  return {
    value,
    onChange,
  };
}

export const useInfoBanner: () => UseInfoBannerHook = () => {
  const { onSendData, onShowModal } = useInfoBannerModal();
  const {
    value: show,
  }: { value: boolean, onChange: (boolean) => mixed } = useValue("open");

  const onCloseBanner = () => {
    onSendData({ isBannerAccepted: false });
  };

  return ({
    show,
    onCloseBanner,
    onShowModal,
  });
};
