// @flow
import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import type { StoreWithForm } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form";
import { getModalFlags } from "../../services/deprecatedApi";
import type { UseState } from "../../components/EditReportButton/types/EditReportButton.types";

export type UseModalSwitchHook = {
  name: string,
};

function useOpen(list: string[]): string {
  const opens: * = useSelector((state: StoreWithForm): * => list.map((k: string): mixed => getFormField(state, k, "open")));
  const openIndex: number = opens.findIndex((open: mixed): boolean => !!open);
  return list[openIndex] || "";
}

export const useModalSwitch: () => UseModalSwitchHook = () => {
  const [keys, setKeys]: UseState<string[]> = useState([]);
  const dispatch: <A>(A) => A = useDispatch();

  useEffect(() => {
    getModalFlags()
      .then((modals: {[string]: boolean}) => {
        const modalKeys: string[] = Object.keys(modals);
        batch(() => {
          modalKeys
            .forEach((modalKey: string):* => dispatch(setFormData(modalKey, { open: modals[modalKey] })));
        });
        setKeys(modalKeys);
      });
  }, [dispatch]);

  return {
    name: useOpen(keys),
  };
};
