// @flow
/* eslint-disable react/require-default-props, react/default-props-match-prop-types */
import React, { type Node } from "react";

type Props = {
  id: string,
  glowSize?: number,
  shadowSize?: number,
  shadowOffsetX?: number,
  shadowOffsetY?: number,
  shadowOpacity?: number,
}
/**
 * Component to define svg shadow glow filter, must be defined in svg -> defs > GlowShadowFilter
 * @param {string} id of filter must be unique in page
 * @param {number} glowSize size of glowing
 * @param {number} shadowSize size of shadow
 * @param {number} shadowOffsetX shadow offset
 * @param {number} shadowOffsetY shadow offset
 * @param {number} shadowOpacity shadow opacity
 * @returns {JSX.Element} filter component
 * @example define filter
 * <svg><defs> <GlowShadowFilter id="someFilterId" /> </defs></svg>
 * @example use for some svg
 * <svg> <circle r={5} filter="url(#someFilterId)" /> </svg>
 * @example use for mui icons
 * <Icon style={{ filter: "url(#someFilterId)"}}
 */
function GlowShadowFilter({
  id,
  glowSize = 52,
  shadowSize = 7,
  shadowOffsetX = 10,
  shadowOffsetY = 20,
  shadowOpacity = 0.4,
}: Props): Node {
  return (
    <filter id={id} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feGaussianBlur
        in="SourceGraphic"
        stdDeviation={glowSize}
        result="coloredBlurGlow"
      />

      <feDropShadow
        in="SourceGraphic"
        dx={shadowOffsetX}
        dy={shadowOffsetY}
        stdDeviation={shadowSize}
        floodOpacity={shadowOpacity}
        result="coloredBlurShadow"
      />

      <feMerge>
        <feMergeNode in="coloredBlurShadow" />
        <feMergeNode in="coloredBlurGlow" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  );
}

export default GlowShadowFilter;
