// @flow
/* eslint-disable import/max-dependencies */
import environment from "environment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserInfo as getUserInfoSelector } from "@fas/cpa-state-manager/services/selectors/userInfo";
import { useHandleRequest, type UseHandleRequestHook } from "@fas/cpa-state-manager/redux/hooks";
import { setLoading } from "@fas/cpa-state-manager/redux/actions/loading";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import { setUserInfo } from "@fas/cpa-state-manager/redux/actions/userInfo";
import { USER_INFO } from "@fas/cpa-state-manager/redux/constants";
import { getUserInfo, type User } from "../../services/deprecatedApi";

export type DefaultUserInfoState = {
  id: string,
  name: string,
  avatarUrl: string,
  email: string,
};

export type UseUserInfoHook = {
  id: string,
  name: string,
  avatarUrl: string,
  email: string,
  isLoading: boolean,
  onViewClick: () => mixed,
};

export const useUserInfo: () => UseUserInfoHook = () => {
  // $FlowFixMe
  const userInfo: DefaultUserInfoState = useSelector(getUserInfoSelector);
  const dispatch: <A>(A) => A = useDispatch();
  const navigate = useNavigate();
  const isFetched: * = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, `${USER_INFO}-fetched`));

  const {
    isLoading,
    changeLoading,
    onFail,
    onFinally,
  }: UseHandleRequestHook = useHandleRequest(USER_INFO);

  useEffect(() => {
    changeLoading(true);

    getUserInfo()
      // $FlowFixMe
      .then((response: { data: User }) => {
        const {
          id = "",
          firstName = "",
          lastName = "",
          email = "",
          photo: avatarUrl,
        }: User = response.data;
        dispatch(setLoading(`${USER_INFO}-fetched`, true));
        dispatch(setUserInfo({
          id,
          avatarUrl,
          name: `${firstName} ${lastName}`,
          email,
        }));
      })
      .catch(onFail)
      .finally(onFinally);
  }, []);

  const onViewClick: () => mixed = () => navigate(environment.links.userInfo);

  return {
    ...userInfo,
    onViewClick,
    isLoading: isLoading && !isFetched,
  };
};
