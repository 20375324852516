// @flow
import React from "react";
import type { Node } from "react";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Flag from "../../icons/Flag";

type Props = {
  sx?: Object,
  open: boolean,
  onClose: () => void
};

function HelpUkraineBanner({ open, onClose, sx }: Props): Node {
  return open && (
    <Box
      sx={{
        ...sx,
        borderRadius: 3.75,
        backgroundColor: "transparent.light",
        padding: { xs: "20px 10px 10px", sm: "20px" },
        width: { xs: "100%", md: "auto" },
      }}
    >
      <Badge
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        componentsProps={{ badge: { sx: { padding: 0, transform: { xs: "scale(1) translate(10%, -30%)", sm: "scale(1) translate(50%, -65%)" } } } }}
        badgeContent={(
          <IconButton data-testid="close" size="small" onClick={onClose}>
            <Close sx={{ color: "transparent.default", fontSize: "1.2rem" }} />
          </IconButton>
        )}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            marginBottom: { xs: "5px", sm: "10px" },
          }}
        >
          <Flag />
          <Typography
            sx={{
              typography: { xs: "subtextBold" },
              margin: { xs: "2px 0 0", sm: "0 0 0 10px" },
              fontSize: { sm: "16px", lg: "18px" },
              fontWeight: { sm: "500", lg: "700" },
              lineHeight: { md: "30px" },
            }}
          >
            Save Ukraine
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Button
            variant="gradient"
            component="a"
            target="_blank"
            href="https://bit.ly/350UJDS"
            sx={{
              height: 40, minWidth: "fit-content", width: "100%", textTransform: "capitalize", fontSize: "14px",
            }}
          >
            Donate
          </Button>
        </Box>
      </Badge>
    </Box>
  );
}

export default HelpUkraineBanner;
