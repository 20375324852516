// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box, Button, Typography, IconButton, Badge,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { type UseInfoBannerHook } from "../../hooks/useInfoBanner/useInfoBanner";

const InfoBanner: StatelessFunctionalComponent<UseInfoBannerHook> = ({
  show,
  onShowModal,
  onCloseBanner,
}) => (
  (show ? (
    <Box
      sx={{
        borderRadius: { xs: 3.75, md: "4px" },
        backgroundColor: "transparent.light",
        padding: { xs: "20px 10px 10px", sm: "20px", md: "10px 50px 10px 10px" },
        marginRight: { md: "40px" },
        width: { xs: "100%", md: "318px" },
      }}
    >
      <Badge
        sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "center" }}
        componentsProps={{
          badge: {
            sx: {
              padding: 0,
              transform: { xs: "translate(10%, -30%)", sm: "translate(50%, -65%)", md: "translate(46px, 6px)" },
            },
          },
        }}
        badgeContent={(
          <IconButton data-testid="close" size="small" onClick={onCloseBanner}>
            <Close sx={{ color: "transparent.default", fontSize: { xs: "1.2rem", md: "24px" } }} />
          </IconButton>
        )}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            margin: { xs: "0 0 5px", sm: "0 0 10px", md: "0 10px 0 0" },
            width: "100%",
          }}
        >
          <Typography variant="gradientH4" sx={{ fontSize: { xs: "18px", md: "24px" }, lineHeight: "1!important" }}>
            %
          </Typography>
          <Typography
            sx={{
              typography: { xs: "subtextBold" },
              margin: { xs: "2px 0 0", sm: "0 0 0 5px" },
              fontSize: { sm: "16px", lg: "18px" },
              fontWeight: { sm: "500", lg: "700" },
              lineHeight: { md: "30px" },
              whiteSpace: "nowrap",
            }}
          >
            Direct offers
          </Typography>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "100px" } }}>
          <Button
            variant="gradient"
            type="button"
            onClick={onShowModal}
            sx={{
              height: { xs: 40, md: 32 }, minWidth: "fit-content", width: "100%", textTransform: "capitalize", whiteSpace: "nowrap", fontSize: "14px",
            }}
          >
            Try now
          </Button>
        </Box>
      </Badge>
    </Box>
  ) : null)
);

export default InfoBanner;
