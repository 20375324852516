// @flow
/* eslint-disable import/max-dependencies */
import { useDispatch, useSelector } from "react-redux";
import type { UseHandleRequestHook } from "@fas/cpa-state-manager/redux/hooks";
import { useHandleRequest } from "@fas/cpa-state-manager/redux/hooks";
import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { getUserInfo as getUserInfoSelector } from "@fas/cpa-state-manager/services/selectors/userInfo";
import { USER_INFO } from "@fas/cpa-state-manager/redux/constants";
import { updateInfoBanner } from "../../services/deprecatedApi";
import { INFO_BANNER } from "../../constants";
import { type DefaultUserInfoState } from "../useUserInfo/useUserInfo";

export type UseInfoBannerModalHook = {
  isLoading: boolean,
  open: boolean,
  email: string,
  affiliateId: string,
  onChangeEmail: (string) => mixed,
  onSendData: (params: { isBannerAccepted: boolean }) => Promise<void>,
  onShowModal: () => void,
  onCloseModal: () => void,
};

function useValue<T>(key: string): { value: T, onChange: (T) => * } {
  const {
    value,
    onChange,
  }: { value: T, onChange: (T) => * } = useFormField(INFO_BANNER, key);

  return {
    value,
    onChange,
  };
}

export const useInfoBannerModal: () => UseInfoBannerModalHook = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const {
    email: affiliateEmail,
    id: affiliateId,
    // $FlowFixMe
  }: DefaultUserInfoState = useSelector(getUserInfoSelector);
  const isLoadingUserInfo: * = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, USER_INFO));
  const isFetchedUserInfo: * = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, `${USER_INFO}-fetched`));

  const {
    value: open,
    onChange: setModalOpen,
  }: { value: boolean, onChange: (boolean) => mixed } = useValue("openModal");

  const {
    value: email = affiliateEmail,
    onChange: onChangeEmail,
  }: { value: string, onChange: (string) => mixed } = useValue("email");

  const {
    onChange: setShowBanner,
  }: { value: boolean, onChange: (boolean) => mixed } = useValue("open");

  const {
    isLoading,
    changeLoading,
    onFail,
    onFinally,
  }: UseHandleRequestHook = useHandleRequest(`${INFO_BANNER}-update`);

  const onShowModal = () => {
    setModalOpen(true);
  };

  const onSendData = async ({ isBannerAccepted = false }: { isBannerAccepted: boolean }) => {
    changeLoading(true);

    updateInfoBanner(email, isBannerAccepted)
      .then(() => {
        setModalOpen(false);
        setShowBanner(false);
        if (isBannerAccepted) {
          dispatch(addNotification({ severity: "success", message: "Thank you! Your request has been successfully sent." }));
        }
      })
      .catch(onFail)
      .finally(onFinally);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  return ({
    isLoading: isLoading || isLoadingUserInfo || !isFetchedUserInfo,
    open: Boolean(open),
    email,
    affiliateId,
    onChangeEmail,
    onSendData,
    onShowModal,
    onCloseModal,
  });
};
